<template>
  <div>
    <form @submit.prevent="submitBooking">
      <input v-model="bookingData.username" placeholder="Username" />
      <input v-model="bookingData.email" placeholder="Email" />
      <input v-model="bookingData.plate_number" placeholder="Plate Number" />
      <input v-model="bookingData.phone_number" placeholder="Phone Number" />
      <input v-model="bookingData.booked_from" type="datetime-local" />
      <input v-model="bookingData.booked_to" type="datetime-local" />
      <button type="submit">Book Slot</button>
    </form>
  </div>
</template>

<script>
import apiService from '/Users/liyawa/Documents/frontend/booking/src/service/api-service.js';

export default {
  data() {
    return {
      bookingData: {
        username: '',
        email: '',
        plate_number: '',
        phone_number: '',
        booked_from: '',
        booked_to: ''
      }
    };
  },
  methods: {
    async submitBooking() {
      try {
        await apiService.bookSlot(this.bookingData);
        alert('Slot booked successfully');
      } catch (error) {
        console.error(error);
        alert('Error booking slot');
      }
    }
  }
};
</script>

