<template>
  <div>
    <!-- Components are shown directly without checking for authentication -->
    <BookingForm />
    <CheckSlot />
    <CheckOut />
  </div>
</template>

<script>
import BookingForm from './components/BookingForm.vue';
import CheckSlot from './components/CheckSlot.vue';
import CheckOut from './components/CheckOut.vue';

export default {
  components: {
    BookingForm,
    CheckSlot,
    CheckOut
  },
  created() {
    this.handleAuthentication();
  },
  methods: {
    handleAuthentication() {
      const currentURL = new URL(window.location.href);
      const hash = currentURL.hash.substring(1);
      const params = new URLSearchParams(hash);
      const token = params.get('id_token'); // Assuming the token is in 'id_token'

      if (!token) {
        // Redirect to the updated Cognito URL if token not present
        const cognitoUrl = 
'https://my-app-domain.auth.eu-central-1.amazoncognito.com/login?client_id=1hsh4fqrfqve2emaqojo034nsk&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fwakuabc.com';
        window.location.href = cognitoUrl;
      } else {
        // Store the token and proceed
        localStorage.setItem('userToken', token);
      }
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

