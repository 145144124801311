import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://2gamdh455a.execute-api.eu-central-1.amazonaws.com/prod', // Your API Gateway URL
  headers: {
    'Content-Type': 'application/json'
  }
});

apiClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('userToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, 
  function (error) {
    // Handle request error here
    return Promise.reject(error);
  }
);

export default {
  bookSlot(slotData) {
    return apiClient.post('/booking', slotData);
  },

  checkSlot(slotInfo) {
    return apiClient.get(`/checkslot`, { params: slotInfo });
  },

  checkoutSlot(slotInfo) {
    return apiClient.patch(`/checkout`, slotInfo);
  }
};

