<template>
  <div>
    <form @submit.prevent="submitCheckSlot">
      <input v-model="checkSlotData.plate_number" placeholder="Plate Number" />
      <input v-model="checkSlotData.phone_number" placeholder="Phone Number" />
      <input v-model="checkSlotData.username" placeholder="Username" />
      <button type="submit">Check Slot</button>
    </form>
  </div>
</template>

<script>
import apiService from '/Users/liyawa/Documents/frontend/booking/src/service/api-service.js'; // Update the path as needed

export default {
  data() {
    return {
      checkSlotData: {
        plate_number: '',
        phone_number: '',
        username: ''
      }
    };
  },
  methods: {
    async submitCheckSlot() {
      try {
        const response = await apiService.checkSlot(this.checkSlotData);
        console.log(response.data); // Log the response data from the server
        alert('Slot check successful');
      } catch (error) {
        console.error("Error checking slot:", error.response ? error.response.data : error);
        alert('Error checking slot');
      }
    }
  }
};
</script>
