<template>
  <div>
    <form @submit.prevent="submitCheckout">
      <input v-model="checkoutData.plate_number" placeholder="Plate Number" />
      <input v-model="checkoutData.phone_number" placeholder="Phone Number" />
      <input v-model="checkoutData.username" placeholder="username" />
      <button type="submit">Checkout Slot</button>
    </form>
  </div>
</template>

<script>
import apiService from '/Users/liyawa/Documents/frontend/booking/src/service/api-service.js';

export default {
  data() {
    return {
      checkoutData: {
        plate_number: '',
        phone_number: '',
        username: ''
      }
    };
  },
  methods: {
    async submitCheckout() {
      try {
        const response = await apiService.checkoutSlot(this.checkoutData);
        console.log(response);
        alert('Checkout successful');
      } catch (error) {
        console.error(error);
        alert('Error during checkout');
      }
    }
  }
};
</script>

